import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, vModelDynamic as _vModelDynamic, normalizeClass as _normalizeClass, withDirectives as _withDirectives, vModelText as _vModelText, createElementVNode as _createElementVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "input validation-textfield" }
const _hoisted_2 = {
  key: 0,
  class: "single-line"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = ["type", "placeholder", "readonly", "maxlength", "oninput", "autocomplete"]
const _hoisted_5 = ["src"]
const _hoisted_6 = ["placeholder", "readonly", "maxlength"]
const _hoisted_7 = {
  key: 0,
  class: "error-area"
}
const _hoisted_8 = { class: "error-message" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_datepicker = _resolveComponent("datepicker")!
  const _component_MenuOptionDropdown = _resolveComponent("MenuOptionDropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["input-area", {
        'none-border': _ctx.noneBorder,
        readonly: _ctx.readonly,
        error: _ctx.errorMessage && !_ctx.hasFocus,
        focus: _ctx.hasFocus && !_ctx.readonly,
      }])
    }, [
      (!_ctx.isMultiline)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_ctx.prefixIcon)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  src: _ctx.prefixIcon,
                  alt: "prefix icon",
                  class: "prefix-icon"
                }, null, 8, _hoisted_3))
              : _createCommentVNode("", true),
            (_ctx.isDatePicker)
              ? (_openBlock(), _createBlock(_component_datepicker, {
                  key: 1,
                  placeholder: _ctx.placeholder,
                  "lower-limit": _ctx.lowerLimitDate,
                  "upper-limit": _ctx.upperLimitDate,
                  modelValue: _ctx.inputValue,
                  "onUpdate:modelValue": [
                    _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
                    _ctx.dateValueChange
                  ],
                  ref: "datePickerRef",
                  onFocus: _ctx.onFocus,
                  onBlur: _ctx.onBlur,
                  readonly: _ctx.readonly,
                  disabled: _ctx.readonly,
                  class: "w-full",
                  inputFormat: _ctx.$t('contractSummary.dateFormat')
                }, null, 8, ["placeholder", "lower-limit", "upper-limit", "modelValue", "onFocus", "onBlur", "onUpdate:modelValue", "readonly", "disabled", "inputFormat"]))
              : _withDirectives((_openBlock(), _createElementBlock("input", {
                  key: 2,
                  type: _ctx.type,
                  placeholder: _ctx.placeholder,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.inputValue) = $event)),
                  onInput: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.valueChanged && _ctx.valueChanged(...args))),
                  class: _normalizeClass({ 'w-full': _ctx.suffixIcon == 'none' }),
                  onFocus: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onFocus && _ctx.onFocus(...args))),
                  onBlur: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onBlur && _ctx.onBlur(...args))),
                  readonly: _ctx.readonly,
                  maxlength: _ctx.maxLength != 0 ? _ctx.maxLength : 100,
                  oninput: _ctx.onInput,
                  autocomplete: ( _ctx.name == 'expireYear' || _ctx.name == 'cvv' || _ctx.name == 'securityCode' ) ? 'new-password' : ''
                }, null, 42, _hoisted_4)), [
                  [_vModelDynamic, _ctx.inputValue]
                ]),
            (_ctx.suffixIcon)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 3,
                  src: _ctx.suffixIcon,
                  alt: "suffix icon",
                  class: "suffix-icon",
                  onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.suffixIconAction && _ctx.suffixIconAction(...args)))
                }, null, 8, _hoisted_5))
              : _createCommentVNode("", true)
          ]))
        : _withDirectives((_openBlock(), _createElementBlock("textarea", {
            key: 1,
            placeholder: _ctx.placeholder,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.inputValue) = $event)),
            onInput: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.valueChanged && _ctx.valueChanged(...args))),
            readonly: _ctx.readonly,
            onFocus: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.onFocus && _ctx.onFocus(...args))),
            onBlur: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.onBlur && _ctx.onBlur(...args))),
            maxlength: _ctx.maxLength != 0 ? _ctx.maxLength : 10000
          }, null, 40, _hoisted_6)), [
            [_vModelText, _ctx.inputValue]
          ])
    ], 2),
    (_ctx.displayErrorMessage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          (_ctx.errorMessage && !_ctx.hasFocus)
            ? (_openBlock(), _createBlock(_component_MenuOptionDropdown, {
                key: 0,
                class: "error-tooltip"
              }, {
                default: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "default", {
                    errorString: _ctx.getMessage(_ctx.errorMessage)
                  }, () => [
                    _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.getMessage(_ctx.errorMessage)), 1)
                  ])
                ]),
                _: 3
              }))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}