
import { defineComponent, ref, setBlockTracking, watch, computed } from "vue";
import { useField } from "vee-validate";
import MenuOptionDropdown from "@/components/atomics/MenuOptionDropdown.vue";
import Datepicker from "vue3-datepicker";

export default defineComponent({
  name: "ValidationTextField",
  components: {
    MenuOptionDropdown,
    Datepicker,
  },
  props: {
    name: { type: String, default: "" },
    value: { type: String, default: "" },
    isMultiline: { type: Boolean, default: false },
    type: { type: String, default: "text" },
    message: { type: Object || String },
    placeholder: { type: String, default: "" },
    rules: { type: String, default: "" },
    suffixIcon: { type: String, default: "" },
    prefixIcon: { type: String, default: "" },
    keepErrorSpace: { type: Boolean, default: true },
    readonly: { type: Boolean, default: false },
    displayErrorMessage: { type: Boolean, default: true },
    noneBorder: { type: Boolean, default: false },

    isValidateFromBlur: { type: Boolean, default: true },
    onInput: { type: String, default: "" },
    maxLength: { type: Number, default: 0 },
    suffixIconAction: { type: Function },

    isDatePicker: { type: Boolean, default: false },
    dateValue: { type: Date },
    isOnlyNumber: { type: Boolean, default: false },

    lowerLimitDate: { type: Date },
    upperLimitDate: { type: Date },
  },
  emits: [
    "update:value",
    "onFocus",
    "onBlur",
    "update:dateValue",
    "onInput",
    "onError",
  ],
  setup(props, { emit }) {
    const {
      value: inputValue,
      errorMessage,
      meta,
      validate,
    } = useField(props.name, props.rules, {
      initialValue: props.isDatePicker ? props.dateValue : props.value,
    });

    const datePickerRef = ref<InstanceType<typeof Datepicker>>();

    const dateValueChange = (date: Date) => {
      emit("update:dateValue", date);
    };

    const hasFocus = ref(false);

    const onBlur = () => {
      hasFocus.value = false;
      if (props.isValidateFromBlur) validate();
      if (!props.isDatePicker)
        inputValue.value = (inputValue.value as string).trim();
      if (props.isOnlyNumber)
        inputValue.value = (inputValue.value as string)
          .replace(/\D/g, "")
          .trim();
      emit("onBlur");
    };

    const onFocus = () => {
      hasFocus.value = true;
      emit("onFocus");
    };

    watch(
      () => props.value,
      (val) => {
        inputValue.value = val;
      }
    );

    watch(
      () => props.dateValue,
      (val) => {
        if (props.isDatePicker && val) inputValue.value = val;
      }
    );

    watch(
      () => errorMessage.value,
      () => {
        emit("onError", getMessage(errorMessage.value));
      }
    );

    const getMessage = (errorName: any): string => {
      setBlockTracking;
      if (typeof props.message === "string") {
        return props.message;
      }
      if (typeof props.message === "object" && props.message[errorName]) {
        return (props.message as Record<string, string>)[errorName];
      }
      return errorName;
    };

    return {
      hasFocus,
      onBlur,
      onFocus,
      errorMessage,
      inputValue,
      meta,
      datePickerRef,
      dateValueChange,
      getMessage,
    };
  },
  methods: {
    valueChanged(value: any) {
      this.$emit(
        "onInput",
        this.inputValue + (value.isComposing ? value?.data ?? "" : "")
      );
      this.$emit("update:value", this.inputValue);
    },
  },
});
